import * as types from '../types/pages.types'
import storage from 'redux-persist/lib/storage'
import persistReducer from 'redux-persist/lib/persistReducer';

const initialState = {
    content:{},
    isLoading: false,
    errMessage: null,
}

const persistConfig = {
    key: 'root',
    storage,
  };

const reducer = (state=initialState,action={})=>{
    switch (action.type){
        case types.CONTENTS_SHOW:return{
            ...state,
            content:action.content,
            isLoading:false,
            errMessage:null,
        }
        case types.CONTENT_FAILED:return{
            ...state,
            isLoading:false,
            errMessage:action.errMessage
        }
        case types.CONTENT_LOADING:return{
            ...state,
            isLoading:true,
            errMessage:null,
        }
        default:return state
    }
}

 const persistedReducer = persistReducer(persistConfig, reducer);

export default persistedReducer