import { useState } from "react"
import { useLocation } from "react-router-dom"
import "../index.css"

const Navbar = (props) => {
    // const { pathname } = useLocation();

     //assigning location variable
     const location = useLocation();

     //destructuring pathname from location
     const { pathname } = location;
 
     //Javascript split method to get the name of the path in array
     const splitLocation = pathname.split("/");
     console.log(splitLocation[1])
    const [isDisplayMenu, toggleMenu] = useState(false)
    const renderActiveMenu = (pathCurrent) => pathCurrent === pathname ? 'active cursor fw-bold' : ''

    return (
        <nav className="navbar row navbar-expand-lg navbar-light shadow-lg" style={{ backgroundColor: "white" }}>
            <div className="container-fluid">
                
                    <a className="navbar-brand px-4 d-none d-sm-block" href="/" onClick={() => { window.location.href = "/" }} title="homepage">
                    </a>

                    <a className="d-block d-sm-none" href="/" onClick={() => { window.location.href = "/" }} title="homepage">
                        <img src="/assets/img/kai-logo-mobile.png" alt="logo-kai-mobile" height={"50px"} />
                    </a>
                    <h1 className="d-inline nav-brand">A/Prof Kai Lee</h1>
                
                <button className="navbar-toggler" type="button"  onClick={() => toggleMenu(!isDisplayMenu)} >
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className={`${isDisplayMenu ? '' : 'collapse'} navbar-collapse`}
                >
                    <ol className="navbar-nav">
                    <li className={`nav-item`} >
                            <a className={`nav-link navbarItem ${renderActiveMenu('/')} `} href='/' title="Home">
                                HOME
                            </a>
                        </li>
                        <li className={`nav-item`} >
                            <a className={`nav-link navbarItem ${renderActiveMenu('/about')} `} href='/about' title="About Dr Kai Lee">
                                About Dr Kai Lee
                            </a>
                        </li>
                        <li className='nav-item dropdown'>
                            <a className={`nav-link dropdown-toggle navbarItem ${renderActiveMenu('#')}`} to="#" role="button" data-bs-toggle="dropdown" aria-expanded="false" title="dropdown for patient" >
                                for PATIENTs
                            </a>
                            <ol className="dropdown-menu">
                                <li>
                                    <a className={`dropdown-item navbarItem ${renderActiveMenu('/registration-patient')}`} href='/registration-patient' title="patient appointment" >patient registration</a>
                                </li>                                
                                <li>
                                    <a className={`dropdown-item navbarItem ${renderActiveMenu('/Pre-Surgery-Information')}`} href='/Pre-Surgery-Information' title="Pre Surgery Information" > Pre Surgery Information</a>
                                </li>
                                <li>
                                    <a className={`dropdown-item navbarItem ${renderActiveMenu('/Post-Surgery-Information')} `} href='/Post-Surgery-Information' title="Post Surgery Information" >Post Surgery Information</a>
                                </li>
                                <li>
                                    <a className={`dropdown-item  navbarItem ${renderActiveMenu('/Faq')}`} href='/Faq'  title="Faq" >FAQ</a>
                                </li>
                                <li>
                                    <a className={`dropdown-item navbarItem ${renderActiveMenu('/payment-option')} `} href='/payment-option' title="Payment Option" >Payment Option</a>
                                </li>
                            </ol>
                        </li>
                        <li className='nav-item dropdown'>
                            <a className={`nav-link dropdown-toggle navbarItem ${renderActiveMenu('#')}`} to="#" role="button" data-bs-toggle="dropdown" aria-expanded="false" title="dropdown referring practionnaire">
                                For Referring Practitioners
                            </a>
                            <ol className="dropdown-menu">
                                <li>
                                    <a className={`dropdown-item navbarItem ${renderActiveMenu('/services-practionnaire')}`} href='/services-practionnaire' title="services">services</a>
                                </li>
                                <li>
                                    <a className={`dropdown-item navbarItem ${renderActiveMenu('/referal')}`} href='/referal' title="Referal practionnaires" >Making a referral</a>
                                </li>
                            </ol>
                        </li>
                        <li className='nav-item'>
                            <a className={`nav-link navbarItem ${renderActiveMenu('/services-general')}`} href='/services-general'  title="services">services</a>
                        </li>
                        <li className='nav-item'>
                            <a className={`nav-link navbarItem ${renderActiveMenu('/contact')}`} href='/contact' title="contact us">contact us</a>
                        </li>
                    </ol>
                </div>
            </div>
        </nav>
    )
}

export default Navbar