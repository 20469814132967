import { lazy, Suspense } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import Footer from './component/FooterNew';
import Main from './component/Main';
import Navbar from './component/Navbar';
const Homepage = lazy(()=>import('./pages/Homepage/Homepage'))
const About=lazy(()=>import("./pages/About/About"))
const Faq =lazy(()=>import("./pages/FAQ/Faq"))
const PostSurgeryInformation=lazy(()=>import("./pages/PostSurgeryInformation/PostSurgeryInformation"))
const PreSurgeryInformation =lazy(()=>import("./pages/PreSurgeryInformation/PreSurgeryInformation"))
const RegistrationPatient=lazy(()=>import("./pages/RegistrationPatient/RegistrationPage"))
const ReferalPractionnaire=lazy(()=>import("./pages/ReferalPractionnaire/ReferealPages"))
const ContactUs=lazy(()=>import("./pages/ContactUs/ContactUs"))
const PaymentOption=lazy(()=>import('./pages/PaymentOption/PaymentOption'))
const Services=lazy(()=>import('./pages/Service/ServicesRoutes'))
const ProfessionalAffiliatePage= lazy (()=> import('./pages/About/ProfesionalAffiliation'))

function App() {
  return (
    <div className='container-fluid' style={{ overflowX: 'hidden' }} >
       <Router>
       <Navbar/>
          <Suspense fallback={<div>Loading...</div>} >
           
            <Main>
              <Switch>
                <Route exact path="/" component={Homepage}/>
                <Route path="/about" component={About} />
                <Route path="/services-general" component={Services}/>
                <Route path="/services-practionnaire" component={Services}/>
                <Route path="/Faq" component={Faq} />
                <Route path="/Post-Surgery-Information" component={PostSurgeryInformation}/>
                <Route path="/Pre-Surgery-Information" component={PreSurgeryInformation} />
                <Route path="/registration-patient" component={RegistrationPatient} />
                <Route path="/referal" component={ReferalPractionnaire} />
                <Route path="/contact" component={ContactUs}/>
                <Route path="/payment-option" component={PaymentOption}/>
                <Route path="/services" component={Services} />
                <Route path="/profesional-affiliate" component={ProfessionalAffiliatePage}/>
              </Switch>
            </Main>
            
          </Suspense>
          <Footer/>
        </Router>
    </div>
  );
}

export default App;
